$size: 4px;

.scrollbar {
  :global {
    .simplebar-vertical {
      width: $size;
      border-radius: $size / 2;
    }

    .simplebar-horizontal {
      height: $size;
    }

    .simplebar-scrollbar {
      &.simplebar-visible {
        &::before {
          opacity: 1;
        }
      }

      &::before {
        background: var(--primary-main);
        border-radius: $size / 2;
        inset: 0;
      }

      &.simplebar-hover {
        &::before {
          opacity: 1;
        }
      }
    }

    .simplebar-track {
      border-radius: $size / 2;
    }
  }
}
