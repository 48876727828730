.wrap {
  background: var(--primary-main);
  color: var(--primary-contrastText);
  padding: 120px 0 60px;
  position: relative;
  border-top: 1px solid var(--primary-contrastText);

  @media (--sm-down) {
    padding: 84px 0 20px;
  }

  .navItem {
    padding-bottom: 2px;
  }

  b {
    font-weight: 500;
  }

  a {
    color: var(--primary-contrastText);
    transition: all ease 0.25s;

    &:hover {
      color: rgb(var(--primary-contrastText-rgb) / 50%);
    }
  }
}

.logo {
  position: absolute;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);
}

.row {
  @media (--md-up) {
    display: flex;
  }

  .col {
    @media (--md-up) {
      width: 25%;
    }

    @media (--md-down) {
      border-bottom: 1px solid var(--grey-500);
    }
  }

  .mdDownShow {
    @media (--md-up) {
      display: none;
    }
  }
}

.title {
  text-transform: uppercase;
  color: var(--primary-contrastText);
  font-weight: 500;
  margin-bottom: 10px;
  cursor: default;

  @media (--md-down) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    margin: 0;
  }

  @media (--sm-down) {
    font-size: 13px;
  }

  .iconWrap {
    font-size: 20px;
    background: var(--grey-500);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--md-up) {
      display: none;
    }

    svg {
      transition: all ease 0.25s;
    }

    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.bottom {
  color: rgb(var(--primary-contrastText-rgb) / 20%);
  margin-top: 60px;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  @media (--lg-down) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (--sm-down) {
    margin-top: 0;
    padding-top: 48px;
  }

  @media (--md-up) {
    border-top: 1px solid rgb(var(--primary-contrastText-rgb) / 20%);
  }

  .menu {
    display: flex;

    @media (--md-down) {
      display: none;
    }
  }

  a {
    color: rgb(var(--primary-contrastText-rgb) / 20%);

    &:hover {
      color: rgb(var(--primary-contrastText-rgb) / 100%);
    }
  }

  .menuItem {
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .icon {
    margin: 0 10px;
    color: var(--primary-contrastText);
    font-size: 13px;
  }
}

.producer,
.producer .link,
.bottom .link {
  color: var(--primary-contrastText);
}
