.topBar {
  background: var(--primary-main);
  color: var(--primary-contrastText);
  padding: 3px 0;
  position: relative;
  z-index: 2;

  .inner {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    @media (--lg-down) {
      display: flex;
      justify-content: center;
    }
  }

  .phone {
    @media (--lg-down) {
      display: none;
    }
  }

  a {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--primary-contrastText);
    transition: all ease 0.25s;

    &:hover {
      color: rgb(var(--primary-contrastText-rgb) / 70%);
    }
  }

  .menuItem {
    @media (--lg-down) {
      &:not(:first-child) {
        display: none;
      }
    }

    &:not(:last-child) {
      @media (--md-up) {
        margin-right: 40px;
      }
    }
  }
}

.wrap {
  position: sticky;
  top: 0;
  background: var(--background-paper);
  z-index: 5;
  left: 0;
  right: 0;
  border-bottom: 1px solid var(--secondary-main);
}

.header {
  position: relative;
  z-index: 2;

  .container {
    @media (--lg-down) {
      padding: 0;
    }
  }

  .inner {
    column-gap: 20px;

    @media (--xl-up) {
      column-gap: 40px;
    }

    @media (--lg-up) {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
    }

    @media (--lg-down) {
      display: flex;
      column-gap: 0;
    }
  }
}

.logo {
  width: 273px;

  @media (--xl-down) {
    width: 220px;
  }

  @media (--lg-down) {
    padding: 8px 24px;
    width: 118px;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (--sm-down) {
    padding: 8px 16px;
    width: 100px;
  }

  img {
    width: 100%;
    height: auto;
  }

  .mdUp {
    @media (--lg-down) {
      display: none;
    }
  }

  .mdDown {
    @media (--lg-up) {
      display: none;
    }
  }
}

.menuIcons {
  display: flex;
  justify-content: flex-end;

  @media (--lg-down) {
    margin-left: auto;
  }

  .btn {
    @media (--lg-down) {
      width: 70px;
      height: 70px;
      border-left: 1px solid var(--secondary-main);
    }

    @media (--sm-down) {
      width: 50px;
      height: 70px;
      border-left: 1px solid var(--secondary-main);
    }

    &.active {
      background: var(--primary-main);
      color: var(--primary-contrastText);
    }
  }
}
