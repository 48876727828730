.mainMenuWrap {
  @media (--lg-down) {
    order: 3;
  }

  &.open {
    .callMenuBtn {
      .line:nth-child(1) {
        rotate: 45deg;
        translate: 0 8px;
        scale: 1.3;
      }

      .line:nth-child(2) {
        opacity: 0;
      }

      .line:nth-child(3) {
        rotate: -45deg;
        translate: 0 -8px;
        scale: 1.3;
      }
    }

    .mainMenu {
      left: 100%;
    }
  }

  .callMenuBtn {
    background: var(--grey-500);
    width: 78px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      background: var(--grey-500);
    }

    @media (--lg-up) {
      display: none;
    }

    .line {
      height: 3px;
      width: 26px;
      background: var(--background-default);
      border-radius: 2px;
      transition: all ease 0.25s;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}

.mainMenu {
  display: flex;
  overflow: hidden;

  @media (--lg-down) {
    flex-direction: column;
    padding: 50px 30px;
    background-color: var(--background-default);
    width: 100vw;
    height: 100vh;
    bottom: -1px;
    translate: -100% 100%;
    left: 0;
    position: absolute;
    transition: all 0.25s;
    z-index: 1;
  }

  a {
    color: var(--text-primary);
  }

  .subMenu {
    position: absolute;
    width: 100%;
    transition: all 0.25s;
    background-color: var(--background-default);

    @media (--lg-up) {
      border-bottom: 2px solid var(--primary-main);
      top: 60px;
      left: 0;
      visibility: hidden;
      z-index: -1;
      transform: scale(1, 0);
      transform-origin: top;
      opacity: 0;
      transition: all 0.35s;
      padding: 37px 30px;
    }

    @media (--xxl-up) {
      padding: 37px 90px;
    }

    @media (--lg-down) {
      height: 100vh;
      padding-bottom: 120px;
      right: -100%;
      top: 0;
      z-index: 2;
      overflow-y: auto;

      &.activeSubMenu {
        right: 0%;
      }
    }
  }
}

.menuItem {
  padding: 37px 0;

  @media (--lg-up) {
    &:hover {
      .subMenu {
        transform: scale(1, 1);
        opacity: 1;
        visibility: visible;
      }

      .arrow {
        rotate: -90deg;
      }

      .parentLink {
        &::after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  .parentLink {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    position: relative;
    font-size: 1vw;
    cursor: pointer;
    color: #000;

    @media (--lg-down) {
      font-size: 13px;
    }

    @media (--xl-up) {
      font-size: 15px;
    }

    @media (--lg-up) {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -15px;
        border-bottom: 2px solid currentcolor;
        opacity: 0;
        transform: translateY(5px);
        transition: all ease 0.25s;
      }
    }
  }

  @media (--lg-down) {
    font-weight: 500;
    font-size: 13px;
    padding: 0;
    border-bottom: 1px solid var(--secondary-main);

    .parentLink {
      width: 100%;
      justify-content: space-between;
      padding: 16px 0;
    }
  }

  &:not(:last-child) {
    margin-right: 25px;

    @media (--xl-up) {
      margin-right: 40px;
    }

    @media (--lg-down) {
      margin-right: 0;
    }
  }

  .box {
    display: flex;
    width: 100%;
  }
}

.arrow {
  background-color: var(--grey-500);
  width: 20px;
  height: 20px;
  color: var(--background-default);
  rotate: 180deg;
  transition: all ease 0.25s;

  @media (--lg-up) {
    rotate: 90deg;
    margin-left: 5px;
    background-color: transparent;
    color: var(--grey-500);
  }
}

.imgBlock {
  width: 100%;
  height: 120px;

  @media (--lg-up) {
    min-width: 350px;
    width: 350px;
    height: auto;
    order: 2;
  }
}

.imgTitle,
.imgDescription {
  display: none;

  @media (--lg-up) {
    display: block;
  }
}

.imgTitle {
  font-weight: 500;
  font-size: 18px;
  margin-top: 10px;
}

.imgDescription {
  margin-top: 10px;
}

.imgWrapper {
  width: 100%;
  height: 120px;
  position: relative;
  overflow: hidden;
  background-color: var(--background-default);

  @media (--lg-up) {
    max-width: 350px;
    height: 350px;
  }

  .img {
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    position: absolute;
    object-fit: cover;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  @media (--lg-up) {
    flex-direction: row;
  }
}

.subMenuItemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 15px 30px;

  @media (--lg-up) {
    width: 100%;
    flex-direction: row;
    padding: 0;
    gap: 52px;
  }
}

.categoriesTitle,
.btnBack {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.categoriesTitle {
  margin-bottom: 20px;

  @media (--lg-up) {
    font-size: 16px;
  }
}

.btnBack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1px solid var(--secondary-main);

  @media (--lg-up) {
    display: none;
  }

  .arrow {
    rotate: 0deg;
  }
}

.linksWrapper {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (--lg-up) {
    font-size: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .link {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 1px solid currentcolor;
      opacity: 0;
      transform: translateY(5px);
      transition: all ease 0.25s;
    }

    &:hover {
      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.linkImage {
  margin-right: 15px;
}
