.root {
  :global {
    .simplebar-vertical {
      visibility: hidden;
    }
  }
}

.forceVisible {
  :global {
    .simplebar-scrollbar {
      &::before {
        opacity: 1 !important;
      }
    }
  }
}
